import React, { useMemo, useCallback } from 'react';
import { LinePath, AreaClosed, Circle } from '@visx/shape';
import { scaleLinear, scaleTime, scaleOrdinal } from '@visx/scale';
import { extent, max } from 'd3-array';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { Group } from '@visx/group';
import { GridRows, GridColumns } from '@visx/grid';
import { LegendOrdinal } from '@visx/legend';
import { useTooltip, TooltipWithBounds } from '@visx/tooltip';
import { localPoint } from '@visx/event';

interface ProfileData {
  minute_of_week: string;
  predicted: number;
  actual: number;
  km3: number;
}

interface ProfileChartProps {
  data: ProfileData[];
  width: number;
  height: number;
}

const ProfileChart: React.FC<ProfileChartProps> = ({ data, width, height }) => {
  const margin = { top: 40, right: 30, bottom: 50, left: 60 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const clusterNames = ['Low', 'Inter.', 'High'];
  const colors = ['green', 'orange', 'red', 'purple'];

  const legendScale = useMemo(() => scaleOrdinal({
    domain: ['Predicted', 'Actual', 'Low', 'Intermediate', 'High'],
    range: ['blue', 'black', 'green', 'orange', 'red'],
  }), []);

  const xScale = useMemo(() => scaleTime({
    range: [0, innerWidth],
    domain: extent(data, d => new Date(d.minute_of_week)) as [Date, Date],
  }), [data, innerWidth]);

  const yScale = useMemo(() => scaleLinear({
    range: [innerHeight, 0],
    domain: [0, max(data, d => Math.max(d.predicted, d.actual, 0)) || 0],
    nice: true,
  }), [data, innerHeight]);

  const {
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
  } = useTooltip<ProfileData>();

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<SVGRectElement>) => {
      const { x, y } = localPoint(event) || { x: 0, y: 0 };
      const x0 = xScale.invert(x - margin.left);
      const index = data.findIndex(d => new Date(d.minute_of_week).getTime() >= x0.getTime());
      const d0 = data[index - 1];
      const d1 = data[index];
      let d = d0;
      if (d1 && x0) {
        d = x0.getTime() - new Date(d0.minute_of_week).getTime() > new Date(d1.minute_of_week).getTime() - x0.getTime() ? d1 : d0;
      }
      showTooltip({
        tooltipData: d,
        tooltipLeft: x,
        tooltipTop: y,
      });
    },
    [showTooltip, xScale, data, margin.left]
  );

  const renderTooltip = useCallback(() => {
    if (!tooltipData) return null;
    return (
      <TooltipWithBounds top={tooltipTop} left={tooltipLeft}>
        <div style={{
          display: 'grid',
          backgroundColor: 'rgba(255, 255, 255, 0.8)', 
          gridTemplateColumns: 'auto auto',
          gap: '4px 8px',
        }}>
          <div><strong>Time:</strong></div>
          <div>{new Date(tooltipData.minute_of_week).toLocaleString('en-US', {
            weekday: 'short',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          })}</div>
          <div><strong>Predicted:</strong></div>
          <div>{tooltipData.predicted.toFixed(2)} kW</div>
          <div><strong>Actual:</strong></div>
          <div>{tooltipData.actual.toFixed(2)} kW</div>
          <div><strong>Cluster:</strong></div>
          <div>{clusterNames[tooltipData.km3]}</div>
        </div>
      </TooltipWithBounds>
    );
  }, [tooltipData, tooltipTop, tooltipLeft]);

  if (data.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <div style={{ position: 'relative' }}>
      <svg width={width} height={height}>
        <Group left={margin.left} top={margin.top}>
          <GridRows scale={yScale} width={innerWidth} strokeDasharray="3,3" stroke="#e0e0e0" />
          <GridColumns scale={xScale} height={innerHeight} strokeDasharray="3,3" stroke="#e0e0e0" numTicks={14} />
          
          <AreaClosed
            data={data}
            x={d => xScale(new Date(d.minute_of_week)) || 0}
            y={d => yScale(d.predicted) || 0}
            yScale={yScale}
            fill="rgba(0, 0, 255, 0.19)"
            stroke="blue"
            strokeWidth={0.5}
          />
          
          {data.map((d, i) => (
            <React.Fragment key={i}>
              <Circle
                cx={xScale(new Date(d.minute_of_week)) || 0}
                cy={yScale(d.predicted) || 0}
                r={3}
                fill="none"
                stroke={colors[d.km3]}
                strokeWidth={1}
              />
              <Circle
                cx={xScale(new Date(d.minute_of_week)) || 0}
                cy={yScale(d.actual) || 0}
                r={2}
                fill={colors[d.km3]}
              />
            </React.Fragment>
          ))}
          
          <AxisLeft 
            scale={yScale} 
            label="kW" 
            tickFormat={(value) => Number.isInteger(value) ? value.toString() : ''}
            numTicks={5}
          />
          <AxisBottom
            scale={xScale}
            top={innerHeight}
            tickFormat={(value: Date | number | { valueOf(): number }, index: number) => `${index + 1}`}
            numTicks={14}
          />
          
          <rect
            x={0}
            y={0}
            width={innerWidth}
            height={innerHeight}
            fill="transparent"
            onMouseMove={handleMouseMove}
            onMouseLeave={hideTooltip}
          />
        </Group>
      </svg>
      
      <div style={{ marginTop: '10px', marginLeft: `${margin.left}px` }}>
        <LegendOrdinal
          scale={legendScale}
          direction="row"
          labelMargin="0 15px 0 0"
          shapeWidth={15}
          shapeHeight={15}
          itemMargin="0 5px"
        />
      </div>
      
      {renderTooltip()}
    </div>
  );
};

export default ProfileChart;
