import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { XYChart, Axis, LineSeries, GlyphSeries, Tooltip } from '@visx/xychart';

import { useScreenSize } from '@visx/responsive';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useAuth} from "react-oidc-context";
import {Toast} from "primereact/toast";
import {MeterReading, ProcessedData} from "../../MeterReading";

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
type DayColors = {
  [day: string]: string;
};
const dayColors :DayColors = {
  'Sunday': '#ff0000',
  'Monday': '#ff7f00',
  'Tuesday': '#ffff00',
  'Wednesday': '#7fff00',
  'Thursday': '#00ff00',
  'Friday': '#00ffff',
  'Saturday': '#0000ff'
};
function useCleanUrlAfterAuth() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if there is a 'state' parameter in the URL
    if (location.search.includes('state')) {
      // Navigate to the same path without query parameters
      navigate(location.pathname, { replace: true });
    }
  }, [navigate, location]);
}

const MeterReadingsChart: React.FC = () => {
  const [data, setData] = useState<ProcessedData[]>([]);
  const { width, height } = useScreenSize({ debounceTime: 150 });
  const auth = useAuth();
  const [visibleDays, setVisibleDays] = useState<Record<string, boolean>>({
    'Sunday': true,
    'Monday': true,
    'Tuesday': true,
    'Wednesday': true,
    'Thursday': true,
    'Friday': true,
    'Saturday': true
  });
  const toast = useRef<Toast>(null);
  const { meterKey } = useParams<{ meterKey: string }>();  // Extracting meterKey from the URL
  axios.defaults.headers.common['Authorization'] = `Bearer ${auth.user?.access_token}`;
  useCleanUrlAfterAuth();
  useEffect(() => {
    if (meterKey) {
      axios.get(`${process.env.REACT_APP_API_URL}/meterreadings/${meterKey}`,).then(response => {
        const parsedAndSortedData = response.data.map((d: MeterReading) => {
          const date = new Date(d.ts);
          return {
            ts: date,
            oat: d.oat,
            expected: d.expected,
            actual: d.actual,
            dayOfWeek: date.getDay()
          };
        }).sort((a: ProcessedData, b: ProcessedData) => a.oat - b.oat);
        setData(parsedAndSortedData);
      })
        .catch(error => {
            toast.current?.show({severity: 'error', summary: 'Error', detail: 'Error loading data!'});
        }
    );
    }
    }, []);

  return (
    <div>
      <h2 className={'title'}>Meter Readings by Day of Week</h2>
      <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
        {days.map(day => (
          <div key={day} style={{ margin: '0 10px', cursor: 'pointer', color: visibleDays[day] ? dayColors[day] : '#ccc' }}
               onClick={() => setVisibleDays(prev => ({
                 ...prev,
                 [day]: !prev[day]
               }))}>
            {day}
          </div>
        ))}
      </div>
      <Toast ref={toast} />
      <XYChart xScale={{ type: 'linear' }} yScale={{ type: 'linear' }} height={height-200} width={width}>
        <Axis orientation="bottom" />
        <Axis orientation="left" />
        {days.map((day, i) => visibleDays[day] && (
          <LineSeries
            key={`line-${i}`}
            dataKey={`Expected-${day}`}
            data={data.filter(d => d.dayOfWeek === i)}
            xAccessor={d => d?.oat}
            yAccessor={d => d?.expected}
            stroke={dayColors[day]}
          />
        ))}
        {days.map((day, i) => visibleDays[day] && (
          <GlyphSeries
            key={`dot-${i}`}
            dataKey={`Actual-${day}`}
            data={data.filter(d => d.dayOfWeek === i)}
            xAccessor={d => d?.oat}
            yAccessor={d => d?.actual}
          />
        ))}
        <Tooltip
          snapTooltipToDatumX
          snapTooltipToDatumY
          showVerticalCrosshair
          showSeriesGlyphs
          renderTooltip={({ tooltipData }) => {
            if (!tooltipData) return null;
            const nearestDatum = tooltipData.nearestDatum;
            if (!nearestDatum) return null;
            const datum = nearestDatum.datum as ProcessedData;
            return (
              <div>
                <strong>Date:</strong> {datum.ts.toLocaleDateString()}<br />
                <strong>OAT:</strong> {datum.oat}<br />
                <strong>Expected:</strong> {datum.expected}<br />
                <strong>Actual:</strong> {datum.actual}
              </div>
            );
          }}
        />
      </XYChart>
      </div>

  );
};

export default MeterReadingsChart;
