import React from 'react';
import { Text } from '@visx/text';

interface LegendProps {
  days: string[];
  colors: string[];
  visibleDays: Set<number>;
  toggleDay: (day: number) => void;
  width: number;
}

const Legend: React.FC<LegendProps> = ({ days, colors, visibleDays, toggleDay, width }) => {
  const legendWidth = width - 80; // Adjust this value as needed
  const itemWidth = legendWidth / 7;

  return (
    <g>
      {days.map((day, index) => (
        <g 
          key={day} 
          transform={`translate(${index * itemWidth}, 0)`}
          style={{ cursor: 'pointer' }}
          onClick={() => toggleDay(index + 1)}
        >
          <circle
            r={4}
            fill={colors[index % colors.length]}
            opacity={visibleDays.has(index + 1) ? 1 : 0.3}
          />
          <Text
            x={10}
            y={5}
            fontSize={10}
            textAnchor="start"
            opacity={visibleDays.has(index + 1) ? 1 : 0.3}
          >
            {day}
          </Text>
        </g>
      ))}
    </g>
  );
};

export default Legend;