import React from 'react';
import {AnimatedAxis, AnimatedLineSeries, XYChart, Tooltip, LineSeries, Axis} from '@visx/xychart';
import moment from 'moment';
import {useScreenSize} from "@visx/responsive";

interface HourlyAveragesChartProps {
  hourlyAverages: { [key: number]: number };
}

interface HourlyAverageData {
  x: string;
  y: number;
}

const WeekProfileChart: React.FC<HourlyAveragesChartProps> = ({ hourlyAverages }) => {
  const data: HourlyAverageData[] = Object.entries(hourlyAverages).map(([minuteOfWeek, value]) => {
    const day = Math.floor(Number(minuteOfWeek) / (24*60))
    const hour = Math.floor((Number(minuteOfWeek) -day* (24*60)) / 60);
    const minute = Number(minuteOfWeek)-(day*24*60)-(hour*60);
    const dayName = moment().day(day).format('dddd'); // Get day name
    return {
      x: `${dayName} ${hour}:${minute}`,
      y: value
    };
  });
  const { width, height } = useScreenSize({ debounceTime: 150 });

  const accessors = {
    xAccessor: (d: HourlyAverageData) => d.x,
    yAccessor: (d: HourlyAverageData) => d.y,
  };

  return (
      <XYChart height={height-400} width={width} xScale={{ type: 'band' }} yScale={{ type: 'linear' }}>

        <LineSeries dataKey="Hourly Averages" data={data} {...accessors} />
        <Axis orientation={ 'bottom' } />
        <Axis orientation={ 'left' } />
        <Tooltip
          snapTooltipToDatumX
          snapTooltipToDatumY
          renderTooltip={({ tooltipData }) => {
            if (!tooltipData) return null;
            const nearestDatum = tooltipData.nearestDatum;
            if (!nearestDatum) return null;
            const datum = nearestDatum.datum as HourlyAverageData;
            return (
              <div>
                <strong>Time:</strong> {datum.x}<br />
                <strong>Average:</strong> {datum.y}
              </div>
            );
          }} />
      </XYChart>
  );
};

export default WeekProfileChart;
