import React, { useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import moment from 'moment';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import WeekProfileChart from "./charts/WeekProfileChart";
import {calculateWeekProfile, CombinedTimeSeries, detectDateFormat, formatData} from "../model/util";
import {TabPanel, TabView} from "primereact/tabview";
import {DateMetrics} from "./interfaces/interfaces";

import Papa from 'papaparse';
import findIdentifierColumns, { findAllDateColumns } from '../model/mapcsv';


interface DataUploadProps {
  onDataLoaded: (data: any[], hourlyAverages: any) => void;
  combinedData: CombinedTimeSeries
}

interface TimeSeriesData {
  [key: string]: any;
}

interface IdentifierColumnInfo {
  columnIndex: number;
  isUniquePerDate: boolean;
}

interface DataRow {
  id1: string;
  id2: string;
  ts:Date
  value:number
  // Add more fields as needed
}

const DataUpload: React.FC<DataUploadProps> = ({ onDataLoaded, combinedData }) => {
  const [previewData, setPreviewData] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [dateMetrics, setDateMetrics] = useState<DateMetrics | null>(null);
  const [hourlyAverages, setHourlyAverages] = useState<any>({});
  const handleFileSelect = (event: any) => {
    const file = event.files[0];
    if (file) {
      Papa.parse< any[][]>(file, {
        header: false,
        dynamicTyping: true,
        complete: (results) => {
          const dateColumns = findAllDateColumns(results.data);
          // sort results.data by date column alphabetically
          results.data.sort((a, b) => 
            a[dateColumns[0].columnIndex] < b[dateColumns[0].columnIndex] ? -1 : 1
          );
          
          const identifierColumns = findIdentifierColumns(results.data, dateColumns);
          console.log(dateColumns);
          console.log(identifierColumns);
        },
      });
    }
  };

  

  

   
  return (
    <div className={'dataupload'}>
      <FileUpload name="demo[]" url="./upload" customUpload uploadHandler={handleFileSelect}
                  accept=".csv" maxFileSize={10000000000} mode="basic" auto chooseLabel="Select CSV File" />
      <TabView className={'tabview'}>

          <TabPanel header="Hourly Averages">
          <WeekProfileChart hourlyAverages={hourlyAverages} />
            </TabPanel>
          <TabPanel header="Preview Data">
        
          </TabPanel>


        <TabPanel header="Date Metrics">

          <p>Start Date: {dateMetrics?.startDate}</p>
          <p>End Date: {dateMetrics?.endDate}</p>
          <p>Total Days: {dateMetrics?.totalDays}</p>
          <p>Readings per Day: {dateMetrics?.readingsPerDay.toFixed(2)}</p>
        </TabPanel>
      </TabView>
    </div>
  );
};

export default DataUpload;
