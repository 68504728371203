import React from 'react';
import '../KPIBox.css'; // We'll create this file for the styles

export interface KPIData {
  title: string;
  value: number | undefined;
  color: string;
  unit?: string;
  decimals?: number;
  icon?: React.ReactNode; // Add an optional icon prop
}

export const KPIBox: React.FC<KPIData> = ({ title, value, color, unit = '', decimals = 2, icon }) => (
  <div className="kpi-box" style={{ borderColor: color }}>
    <div className="kpi-content">
      {icon && <div className="kpi-icon" style={{ color }}>{icon}</div>}
      <div className="kpi-info">
        <h3 className="kpi-title">{title}</h3>
        <div className="kpi-value" style={{ color }}>
          {value !== undefined ? `${value.toFixed(decimals)}${unit}` : 'N/A'}
        </div>
      </div>
    </div>
  </div>
);