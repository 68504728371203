// src/Dashboard.tsx
import React, {useEffect, useRef, useState} from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useAuth } from "react-oidc-context";
import axios from "axios";
import {IntegrationReading} from "../MeterReading";
import {useParams} from "react-router-dom";
import {Toast} from "primereact/toast";
import {interpolateArray} from "./interfaces/interfaces";

interface EnergyData {
  name: string
  uv: number
  pv: number
  amt: number
}

const Dashboard: React.FC = () => {
  const [data, setData] = useState<IntegrationReading[]>([]);
  const auth = useAuth();
  const { namespace, name } = useParams<{ namespace: string, name:string }>();  // Extracting meterKey from the URL
  axios.defaults.headers.common['Authorization'] = `Bearer ${auth.user?.access_token}`;
  const toast = useRef<Toast>(null);
  const token = auth.user?.access_token;
  useEffect(() => {
    if (!token) {
      const currentUrl = window.location.pathname + window.location.search;
      sessionStorage.setItem('postLoginRedirect', currentUrl);
      auth.signinRedirect();
    }
    if (namespace && name) {
      axios.get(`${process.env.REACT_APP_API_URL}/integration/${namespace}/${name}`,).then(response => {
        const parsedAndSortedData = response.data.map((d: IntegrationReading) => {
          const date = new Date(d.ts);
          return {
            ts: date,
            readings: d.readings,
            temperature: interpolateArray(d.temperature)
          };
        });
        setData(parsedAndSortedData);
      })
        .catch(error => {
            toast.current?.show({severity: 'error', summary: 'Error', detail: 'Error loading data!'});
          }
        );

    }
  }, [auth,name,namespace,token]);

  return (
<ResponsiveContainer width="100%" height={300}>
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
        <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
      </LineChart>
</ResponsiveContainer>
  );
}

export default Dashboard;

