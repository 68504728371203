import moment from "moment/moment";
import {DataPoint} from "../components/interfaces/interfaces";

export const detectDateFormat = (data: any[]): string => {
  const dateSamples = data.map(row => row['Date']).slice(0, 10); // Take a sample of up to 10 dates
  const formats = [
    'MM/DD/YYYY',
    'DD/MM/YYYY',
    'YYYY-MM-DD',
    'DD-MM-YYYY',
    'YYYY/MM/DD'
  ];
  for (const format of formats) {
    if (dateSamples.every(date => moment(date, format, true).isValid())) {
      return format;
    }
  }
  return ''; // Return empty if no conclusive format is found
};
export const convertFormatOneToFormatTwo = (data: any[], dateFormat: string): any[] => {
  let formattedData: any[] = [];
  data.forEach(row => {
    const date = row['Date'];
    Object.keys(row).filter(key => key !== 'Date').forEach((key, index) => {
      const minutes = index * 30+120; // Calculate time offset based on column index
      const datetime = moment(date, dateFormat).add(minutes, 'minutes').toDate()
      formattedData.push({ 'DateTime': datetime, 'Reading': parseFloat(row[key]) });
    });
  });
  return formattedData;
};
export const formatData = (data: any[], format: string): any[] => {
  if (!format) {
    alert('Date format could not be automatically detected. Please specify the date format.');
    return data; // Optionally halt further processing or allow user input
  }
  if (data[0] && Object.keys(data[0]).length > 2) { // Assuming it's Format 1
    return convertFormatOneToFormatTwo(data, format);
  }

  return data; // Assuming it's already Format 2
};


export const calculateWeekProfile = (data: Reading[]) => {
  // Object to hold the sum of readings and their counts
  const aggregates = {};

  data.forEach(item => {
    const datetime = moment(item.DateTime, 'YYYY-MM-DD HH:mm');
    const minuteOfWeek = datetime.day() * 24*60 + datetime.hour() * 60 + datetime.minute(); // Converts day and hour to a unique key (0-167)


    // @ts-ignore
    if (aggregates[minuteOfWeek]) {

      // @ts-ignore
      aggregates[minuteOfWeek].sum += item.Reading
      // @ts-ignore
      aggregates[minuteOfWeek].count += 1;
    } else {
      // @ts-ignore
      aggregates[minuteOfWeek] = {
        sum: item.Reading,
        count: 1
      };
    }
  });

  // Calculating averages
  const averages = {};
  for (let hourOfWeek in aggregates) {
    // @ts-ignore
    averages[hourOfWeek] = aggregates[hourOfWeek].sum / aggregates[hourOfWeek].count;
  }

  return averages;
};
export interface Reading{
  DateTime: Date;
  Reading: number;
}

export interface WeekProfile {

}

export interface WeekProfile {
  [hour: number]: number; // Hour of week (0-167) to average value
}

export interface OccupancyHours {
  [day: string]: boolean[]; // Day of the week to array of 96 boolean values (15-min intervals)
}

export function transformToOccupancyHoursUsingMean(weekProfile: WeekProfile): OccupancyHours {
  const occupancyHours: OccupancyHours = {
    'Sunday': new Array(96).fill(false),
    'Monday': new Array(96).fill(false),
    'Tuesday': new Array(96).fill(false),
    'Wednesday': new Array(96).fill(false),
    'Thursday': new Array(96).fill(false),
    'Friday': new Array(96).fill(false),
    'Saturday': new Array(96).fill(false)
  };

  // Calculate mean consumption
  const allValues = Object.values(weekProfile);
  const meanConsumption = allValues.reduce((acc, cur) => acc + cur, 0) / allValues.length;

  // Transform hourly averages into 15-minute occupancy data
  Object.entries(weekProfile).forEach(([minuteOfWeek, average]) => {
    const dayIndex = Math.floor(Number(minuteOfWeek) / (24*60)); // Determine the day of the week (0-6)
    const minuteOfDay = Number(minuteOfWeek) % (24*60); // Determine the hour in the day (0-23)

    const dayName = Object.keys(occupancyHours)[dayIndex]; // Map index to day name

    // Determine occupancy for four quarters of the hour
    occupancyHours[dayName][ minuteOfDay] = average >= meanConsumption;

  });
  console.log("Occupancy Hours",occupancyHours)
  return occupancyHours;
}

export function aggregateData(dataArrays: string[]): (null | Reading)[] {
  return dataArrays.flatMap(data => {
    const lines = data.split('\n');
    return lines.map(line => {
      if (line.length < 22) return null; // Ensure the line is long enough

      const year = line.substring(0, 4);
      const month = line.substring(5, 7);
      const day = line.substring(8, 10);
      const hour = line.substring(11, 13);
      const temperature = parseInt(line.substring(14, 19).trim())/10;

      const timestamp = `${year}-${month}-${day}T${hour}:00:00`;
      return {DateTime:new Date(timestamp), Reading:temperature} as Reading;
    }).filter(entry => entry !== null && entry.Reading > -50); // Filter out invalid entries
  });
}
type TimeSeries = Reading[];


export type CombinedTimeSeries = DataPoint[];
export function mergeTimeSeries(ts1: TimeSeries, ts2: TimeSeries): CombinedTimeSeries {
  const ts2Map = new Map(ts2.map(entry => [entry.DateTime.getTime(), entry.Reading]));
  console.log(ts1, ts2)
  const merged: CombinedTimeSeries = [];

  ts1.forEach(entry => {
    const ts1Date = new Date(entry.DateTime);
    let ts2Reading = ts2Map.get(ts1Date.getTime());

    if(ts2Reading !== undefined) {
      merged.push({
        timestamp: new Date(entry.DateTime),
        consumption: parseFloat('' + entry.Reading),
        oat: ts2Reading
      });
    }
  });

  return merged;
}

export function interpolateToHalfHourly(ts: TimeSeries): TimeSeries {
  const interpolated: TimeSeries = [];

  for (let i = 0; i < ts.length - 1; i++) {
    const current = ts[i];
    const next = ts[i + 1];

    const currentDate = current.DateTime
    const halfHourDate = new Date(currentDate.getTime() + 30 * 60000); // 30 minutes later

    const currentReading = current.Reading;
    const nextReading = next.Reading;

    // Linear interpolation for the halfway point
    const halfHourReading = (currentReading + nextReading) / 2;

    // Push the current hour
    interpolated.push({
      DateTime: currentDate,
      Reading: currentReading
    });

    // Push the interpolated half hour
    interpolated.push({
      DateTime: halfHourDate,
      Reading: halfHourReading
    });
  }

  // Add the last reading (assuming no change in the last half hour as we can't interpolate without a next point)
  if (ts.length > 0) {
    const lastReading = ts[ts.length - 1];
    interpolated.push({
      DateTime: lastReading.DateTime,
      Reading: lastReading.Reading
    });
  }

  return interpolated;
}
export function getColorByMinuteOfWeek(date:Date) {
  // Calculate the minute of the week
  const dayOfWeek = date.getDay(); // Day of week (0 for Sunday, 6 for Saturday)
  const hourOfDay = date.getHours(); // Hour of the day (0-23)
  const minuteOfHour = date.getMinutes(); // Minute of the hour (0-59)
  const minuteOfWeek = (dayOfWeek * 1440) + (hourOfDay * 60) + minuteOfHour;

  // Normalize the minute of the week to a range of 0 to 1
  const normalized = minuteOfWeek / 10079;

  // Convert normalized value to RGB (using a simple hue rotation in HSV color space)
  // HSV to RGB conversion
  const hue = normalized * 360; // Full spectrum rotation
  const saturation = 1; // Full saturation
  const value = 1; // Full brightness

  return hsvToRgb(hue, saturation, value);
}

function hsvToRgb(h: number, s: number, v: number) {
  const f = (n: number, k = (n + h / 60) % 6) => v - v * s * Math.max(0, Math.min(k, 4 - k, 1));
  const r = Math.floor(f(5) * 255);
  const g = Math.floor(f(3) * 255);
  const b = Math.floor(f(1) * 45);
  return `rgb(${r}, ${g}, ${b})`;
}

export function getColorByHourOfWeek(date: { getDay: () => any; getHours: () => any; }) {
  // Calculate the hour of the week
  const dayOfWeek = date.getDay(); // Day of week (0 for Sunday, 6 for Saturday)
  const hourOfDay = date.getHours(); // Hour of the day (0-23)
  const hourOfWeek = (dayOfWeek * 24) + hourOfDay;

  // Normalize the hour of the week to a range of 0 to 1
  const normalized = hourOfWeek / 167;

  // Convert normalized value to RGB (using a simple hue rotation in HSV color space)
  return hsvToRgb(normalized * 360, 1, 1); // Full saturation and value for bright colors
}

export const getHourOfWeek = (dateTime: Date) => {

  return dateTime.getDay() * 24 + dateTime.getHours();
}

export function getISOWeekNumber(date:Date) {
  return moment(date).isoWeek();
}

export  function parseDateString(dateString:string) {

  if (!dateString||dateString.length !== 8) {
   return new Date(0)
  }

  // Extract year, month, and day from the string
  const year = parseInt(dateString.substring(0, 4), 10);
  const month = parseInt(dateString.substring(4, 6), 10) - 1; // Months are 0-based in JavaScript
  const day = parseInt(dateString.substring(6, 8), 10);

  // Create a new Date object
  const date = new Date(year, month, day);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return new Date(0);
  }

  return date;
}










