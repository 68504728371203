import React from 'react';
import { XYChart, Axis, DataProvider, Tooltip, GlyphSeries } from '@visx/xychart';
import {CombinedTimeSeries, getColorByHourOfWeek, getColorByMinuteOfWeek} from "../../model/util";





interface XYPlotProps {
  data: CombinedTimeSeries;
}

const XYPlot: React.FC<XYPlotProps> = ({ data }) => {
  const xScaleConfig = {
    type: 'linear' as const,
    domain: [Math.min(...data.map(d => d.oat)), Math.max(...data.map(d => d.oat))],
  };
  const yScaleConfig = {
    type: 'linear' as const,
    domain: [0, Math.max(...data.flatMap(d => d.consumption))],
    nice: true,
  };

  return (
    <DataProvider xScale={xScaleConfig} yScale={yScaleConfig}>
      <XYChart height={400} width={800}>
        <Axis orientation="bottom" />
        <Axis orientation="left" />

        {/* Consumption */}
        <GlyphSeries dataKey="Consumption" data={data} xAccessor={d => d?.oat} yAccessor={d => d?.consumption}
                     renderGlyph={(props, deprecatedLegacyContext) => (
                       <circle cx={props.x} cy={props.y} fill="#ff0000" r={2} />
                     )}
        />

        {/* Production */}


      </XYChart>
    </DataProvider>
  );
};

export default XYPlot;
