import _ from 'lodash';
import { ModeledDataItem } from '../domain/ModeledDataItem';
import { NumberedModelRow } from '../domain/NumberedModelRow';
/**
 * Calculates the cumulative sum of an array of numbers.
 * @param arr - Array of numbers.
 * @returns Array of cumulative sums.
 */
function cumsum(arr: number[]): number[] {
    const result: number[] = [];
    let sum = 0;
    for (const num of arr) {
      sum += num;
      result.push(sum);
    }
    return result;
  }

  /**
 * Detects peaks in a numerical series.
 * @param series - Array of numbers representing the series.
 * @param span - Number of elements to consider on each side of a point.
 * @returns Array of booleans where `true` indicates a peak.
 */
function detectPeaks(series: number[], span: number): boolean[] {
    const peaks: boolean[] = new Array(series.length).fill(false);
    
    for (let i = span; i < series.length - span; i++) {
      let isPeak = true;
      for (let j = i - span; j <= i + span; j++) {
        if (series[j] > series[i]) {
          isPeak = false;
          break;
        }
      }
      peaks[i] = isPeak;
    }
    
    return peaks;
  }
/**
 * Translates the R `number_events` function to TypeScript.
 * @param model - Array of ModelRow objects.
 * @returns Array of NumberedModelRow objects with `event_no` added.
 */
export function numberEvents(model: ModeledDataItem[]): NumberedModelRow[] {
    // Step 1: Sort the model by timestamp
    const sortedModel = [...model].sort((a, b) => new Date(a.ts).getTime() - new Date(b.ts).getTime());
  
    // Step 2: Calculate the cumulative sum of residuals
    const residArray = sortedModel.map(row => row.waste);
    const rc = cumsum(residArray);
  
    // Step 3: Attach the cumulative residuals to the sorted model
    const validDayData = sortedModel.map((row, index) => ({
      ...row,
      rc: rc[index],
    }));
    console.log("#####################################")
    console.log(validDayData)
    // Step 4: Detect local peaks and troughs
    const span = 15; // Rolling window span
    const rcValues = validDayData.map(row => row.rc);
    
    const localPeaks = detectPeaks(rcValues, span);
    const localTroughs = detectPeaks(rcValues.map(val => -val), span); // Detect troughs by inverting the series
  
    // Step 5: Remove consecutive peaks and troughs
    for (let i = 1; i < localPeaks.length; i++) {
      if (localPeaks[i] && localPeaks[i - 1]) {
        localPeaks[i] = false;
      }
    }
  
    for (let i = 1; i < localTroughs.length; i++) {
      if (localTroughs[i] && localTroughs[i - 1]) {
        localTroughs[i] = false;
      }
    }
  
    // Step 6: Calculate change_days (1 for peak, -1 for trough, 0 otherwise)
    const changeDays = localPeaks.map((peak, i) => (peak ? 1 : 0) - (localTroughs[i] ? 1 : 0));
  
    // Step 7: Calculate event_no as 1 + cumulative sum of non-zero change_days
    let cumulativeEvents = 1;
    const eventNos: number[] = [];
  
    for (const change of changeDays) {
      if (change !== 0) {
        cumulativeEvents += 1;
      }
      eventNos.push(cumulativeEvents);
    }
  
    // Step 8: Extract ts and event_no
    const events = validDayData.map((row, i) => ({
      ts: row.ts,
      event_no: eventNos[i],
    }));
  
    // Step 9: Perform a left join with the original model by ts
    const numberedModel: NumberedModelRow[] = model.map(row => {
      const event = events.find(e => e.ts === row.ts);
      return {
        ...row,
        event_no: event ? event.event_no : 0, // Replace NA with 0
      };
    });
  
    // Step 10: Log the result
    console.log(`Model With Events: ${numberedModel.length} rows, columns: ${Object.keys(numberedModel[0]).join(', ')}`);
  
    return numberedModel;
  }
  
  

/**
 * Filters the model data to include only rows with finite event_no and used flag set to true.
 * @param model - Array of NumberedEventModelRow objects.
 * @returns Filtered array of NumberedEventModelRow objects.
 */
function filterModelData(model: NumberedModelRow[]): NumberedModelRow[] {
    return model.filter(row => isFinite(row.event_no) && row.used);
  }       
  


/**
 * Summarizes events by event_no.
 * @param filteredData - Array of filtered NumberedEventModelRow objects.
 * @param overallMaxTs - The maximum timestamp in the entire dataset.
 * @returns Array of EventSummary objects.
 */
export function summarizeEvents(filteredData: NumberedModelRow[], overallMaxTs: string): EventSummary[] {
  // Group by event_no
  const grouped = _.groupBy(filteredData, 'event_no');

  // Summarize each group
  const summaries: EventSummary[] = Object.entries(grouped).map(([event_no, group]) => {
    const eventNumbers = group.map(row => row.event_no);
    const tsDates = group.map(row => new Date(row.ts));
    const residValues = group.map(row => row.waste);
    const predValues = group.map(row => row.expected);

    const event_started = _.minBy(group, row => new Date(row.ts))!.ts;
    const event_to = _.maxBy(group, row => new Date(row.ts))!.ts;
    const waste = _.mean(residValues) || 0;
    const expected = _.mean(predValues) || 0;
    const open_ended = new Date(event_to) >= new Date(overallMaxTs);
    const open_duration = Math.ceil((new Date(event_to).getTime() - new Date(event_started).getTime()) / (1000 * 60 * 60 * 24)) + 1;
    console.log(event_no, event_started, event_to, waste, expected, open_ended, open_duration);
    return {
      event_no: parseInt(event_no, 10),
      event_started,
      event_to,
      waste,
      expected,
      open_ended,
      open_duration,
    };
  });

  return summaries;
}

