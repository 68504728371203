import React, { useMemo, useState, useCallback } from 'react';
import { scaleLinear } from '@visx/scale';
import { Group } from '@visx/group';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { LinePath, Line } from '@visx/shape';
import { extent } from 'd3-array';
import { Text } from '@visx/text';
import { useTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { NumberedModelRow } from '../../domain/NumberedModelRow';
import Legend from '../Legend';
import { Grid } from '@visx/grid';

interface OATChartProps {
  data: NumberedModelRow[];
  width: number;
  height: number;
}

const colors = ["#1B9E77", "#D95F02", "#7570B3", "#E7298A", "#66A61E", "#E6AB02", "#A6761D"];
const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const getDayName = (isoDow: number): string => days[(isoDow - 1) % 7];

const OATChart: React.FC<OATChartProps> = ({ data, width, height }) => {
  const margin = { top: 20, right: 20, bottom: 80, left: 60 };
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const [visibleDays, setVisibleDays] = useState<Set<number>>(new Set([1, 2, 3, 4, 5, 6, 7]));

  const {
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
  } = useTooltip<NumberedModelRow>();

  const xScale = useMemo(
    () => scaleLinear<number>({
      domain: extent(data, d => d.oat) as [number, number],
      range: [0, xMax],
      nice: true,
    }),
    [xMax, data]
  );

  const yScale = useMemo(
    () => scaleLinear<number>({
      domain: extent(data, d => d.rate) as [number, number],
      range: [yMax, 0],
      nice: true,
    }),
    [yMax, data]
  );

  const [hoveredPoint, setHoveredPoint] = useState<NumberedModelRow | null>(null);

  const toggleDay = useCallback((isoDow: number) => {
    setVisibleDays(prev => {
      const newSet = new Set(prev);
      if (newSet.has(isoDow)) {
        newSet.delete(isoDow);
      } else {
        newSet.add(isoDow);
      }
      return newSet;
    });
  }, []);

  const filteredData = useMemo(() => data.filter(d => visibleDays.has(d.iso_dow)), [data, visibleDays]);

  const groupedData = useMemo(() => {
    const groups: { [key: number]: NumberedModelRow[] } = {};
    filteredData.forEach(row => {
      if (!groups[row.iso_dow]) {
        groups[row.iso_dow] = [];
      }
      groups[row.iso_dow].push(row);
    });
    return Object.entries(groups)
      .map(([iso_dow, rows]) => ({
        iso_dow: parseInt(iso_dow),
        rows: rows.sort((a, b) => a.oat - b.oat)
      }));
  }, [filteredData]);

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<SVGElement>) => {
      const { x, y } = localPoint(event) || { x: 0, y: 0 };
      const xOAT = xScale.invert(x - margin.left);
      const closestPoint = data.reduce((prev, curr) =>
        Math.abs(curr.oat - xOAT) < Math.abs(prev.oat - xOAT) ? curr : prev
      );
      
      setHoveredPoint(closestPoint);
      showTooltip({
        tooltipData: closestPoint,
        tooltipLeft: x,
        tooltipTop: y,
      });
    },
    [showTooltip, xScale, data, margin.left]
  );

  const handleMouseLeave = useCallback(() => {
    hideTooltip();
    setHoveredPoint(null);
  }, [hideTooltip]);

  return (
    <div style={{ position: 'relative' }}>
      <svg width={width} height={height}
           onMouseMove={handleMouseMove}
           onMouseLeave={handleMouseLeave}>
        <Group left={margin.left} top={margin.top}>
          <Grid
            xScale={xScale}
            yScale={yScale}
            width={xMax}
            height={yMax}
            stroke="#e0e0e0"
            strokeOpacity={0.5}
          />

          {filteredData.map((d, i) => (
            <circle
              key={i}
              cx={xScale(d.oat)}
              cy={yScale(d.rate)}
              r={3}
              fill="none"
              stroke={colors[(d.iso_dow - 1) % colors.length]}
            />
          ))}

          {groupedData.map(({ iso_dow, rows }, index) => (
            <LinePath
              key={iso_dow}
              data={rows}
              x={d => xScale(d.oat)}
              y={d => yScale(d.expected)}
              stroke={colors[index % colors.length]}
              strokeWidth={1.5}
            />
          ))}

          <AxisLeft 
            scale={yScale} 
            left={xScale(0)}
            label="Power (kW)"
            numTicks={6}
            tickFormat={(value) => `${Math.round(+value)}`}
          />
          <AxisBottom 
            scale={xScale} 
            top={yScale(0)}
            label="OAT (°C)"
          />

          <Text
            angle={-90}
            width={yMax}
            y={-margin.left + 15}
            x={-yMax / 2}
            textAnchor="middle"
          >
            Power (kW)
          </Text>

          <g transform={`translate(0, ${yMax + 40})`}>
            <Legend
              days={days}
              colors={colors}
              visibleDays={visibleDays}
              toggleDay={toggleDay}
              width={xMax}
            />
          </g>
          {tooltipData && tooltipLeft !== undefined && (
            <Line
              from={{ x: tooltipLeft - margin.left, y: 0 }}
              to={{ x: tooltipLeft - margin.left, y: yMax }}
              stroke="#999"
              strokeWidth={1}
              pointerEvents="none"
            />
          )}
        </Group>
      </svg>
      {hoveredPoint && (
        <div
          style={{
            position: 'absolute',
            top: tooltipTop,
            left: tooltipLeft,
            transform: 'translate(-50%, -100%)',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', 
            padding: '8px',
            borderRadius: '4px',
            boxShadow: '0 1px 10px rgba(0,0,0,0.1)',
            pointerEvents: 'none',
          }}
        >
          <div><strong>OAT:</strong> {hoveredPoint.oat?.toFixed(2)}°C</div>
          <div><strong>Rate:</strong> {hoveredPoint.rate?.toFixed(2)} kW</div>
          <div><strong>Expected:</strong> {hoveredPoint.expected?.toFixed(2)} kW</div>
          <div><strong>Day:</strong> {getDayName(hoveredPoint.iso_dow)}</div>
        </div>
      )}
    </div>
  );
};

export default OATChart;
