import { NumberedModelRow } from "../domain/NumberedModelRow";

// return latest 364 rows, sorted ascending by timestamp
export function annual_model(data: NumberedModelRow[]): NumberedModelRow[] {
    return data
        .sort((a, b) => new Date(a.ts).getTime() - new Date(b.ts).getTime())
        .slice(-364)
        .sort((a, b) => new Date(a.ts).getTime() - new Date(b.ts).getTime());
}

// Symmetrie des Abfalls vs. OAT-Trend
//
// Bestimmt, ob der Abfall auftritt, wenn die Temperaturen fallen oder steigen
// @param annualFullModel Die modellierten Messwerte
// @return Ein Objekt mit dem durchschnittlichen Abfall für steigende und fallende Temperaturen


  
 export function annualAsymmetryWaste(annualFullModel: NumberedModelRow[]): { rising: number | null; falling: number | null } {
    const N = annualFullModel.length;
    if (N === 0) {
      return { rising: null, falling: null };
    }
  
    // 'rising' Array berechnen
    const risingArray: number[] = [0]; // Das erste Element ist 0
    for (let i = 1; i < N; i++) {
      const diff = annualFullModel[i].oat - annualFullModel[i - 1].oat;
      risingArray.push(Math.sign(diff));
    }
  
    // Daten nach 'rising' gruppieren und Summen und Zählungen berechnen
    const groups: { [key: number]: { sumWaste: number; count: number } } = {};
  
    for (let i = 0; i < N; i++) {
      const rising = risingArray[i];
      const waste = annualFullModel[i].waste;
  
      if (!groups[rising]) {
        groups[rising] = { sumWaste: 0, count: 0 };
      }
  
      groups[rising].sumWaste += waste;
      groups[rising].count += 1;
    }
  
    // Durchschnittlichen Abfall für steigende und fallende Temperaturen berechnen
    const risingMean = groups[1] ? groups[1].sumWaste / groups[1].count : null;
    const fallingMean = groups[-1] ? groups[-1].sumWaste / groups[-1].count : null;
  
    return { rising: risingMean, falling: fallingMean };
  }

  // Jahres-, saisonaler Grundlast & Balance
//
// Bestimmt die durchschnittliche jährliche Last, die minimale saisonale Grundlast und die Balance-Temperatur basierend auf der Außentemperatur (OAT)
//
// @param annualFullModel Die modellierten Messwerte
// @return Ein Objekt mit der durchschnittlichen jährlichen Last, der Grundlast und der medianen Balance-OAT

 

interface GroupSummary {
    annual_load: number;
    baseload: number;
    balance: number;
}

function mean(numbers: number[]): number {
    const total = numbers.reduce((acc, val) => acc + val, 0);
    return numbers.length > 0 ? total / numbers.length : NaN;
}

function median(numbers: number[]): number {
    const sorted = numbers.slice().sort((a, b) => a - b);
    const mid = Math.floor(sorted.length / 2);
    if (sorted.length % 2 !== 0) {
        return sorted[mid];
    } else {
        return (sorted[mid - 1] + sorted[mid]) / 2;
    }
}

export function annualSeasonalBaseloadAndBalance(annualFullModel: NumberedModelRow[]): {
    annual_load: number | null;
    seasonalbaseload: number | null;
    balance: number | null;
} {
    if (annualFullModel.length === 0) {
        return { annual_load: null, seasonalbaseload: null, balance: null };
    }

    // Schritt 1: Sortiere nach 'ts' und 'oat', um das minimale Gleichgewicht zu erhalten
    annualFullModel.sort((a, b) => {
        const tsComparison = (a.ts > b.ts) ? 1 : (a.ts < b.ts) ? -1 : 0;
        if (tsComparison !== 0) {
            return tsComparison;
        }
        return a.oat - b.oat;
    });

    // Schritt 2: Wähle die letzten 364 Einträge aus
    const slicedData = annualFullModel.slice(-364);

    // Schritt 3: Gruppiere nach 'iso_dow'
    const groupedByIsoDow: { [key: string]: NumberedModelRow[] } = {};

    slicedData.forEach(entry => {
        const key = String(entry.iso_dow);
        if (!groupedByIsoDow[key]) {
            groupedByIsoDow[key] = [];
        }
        groupedByIsoDow[key].push(entry);
    });

    // Schritt 4: Berechne Gruppenzusammenfassungen
    const groupSummaries: GroupSummary[] = [];

    for (const key in groupedByIsoDow) {
        const group = groupedByIsoDow[key];
        const preds = group.map(entry => entry.expected);
        const oats = group.map(entry => entry.oat);

        const annualLoad = mean(preds);
        const baseload = Math.min(...preds);
        const indexOfMinPred = preds.indexOf(baseload);
        const balanceOat = oats[indexOfMinPred];

        groupSummaries.push({
            annual_load: annualLoad,
            baseload: baseload,
            balance: balanceOat
        });
    }

    // Schritt 5: Entgruppiere und fasse zusammen
    const annualLoads = groupSummaries.map(gs => gs.annual_load);
    const baseloads = groupSummaries.map(gs => gs.baseload);
    const balances = groupSummaries.map(gs => gs.balance);

    const finalAnnualLoad = mean(annualLoads);
    const finalSeasonalBaseload = mean(baseloads);
    const finalBalance = median(balances);

    return {
        annual_load: finalAnnualLoad,
        seasonalbaseload: finalSeasonalBaseload,
        balance: finalBalance
    };
}

// U-förmige Wetterreaktion
//
// In welchem Ausmaß ist die Änderungsrate der erwarteten Werte in Bezug auf die Außentemperatur (OAT)
// positiv und monoton steigend in Bezug auf die OAT (Median nach Wochentag)
//
// @param annualFullModel Die modellierten Messwerte
// @return Median der Compensation Gradient Monotonicity nach ISO-Wochentag

 
 export function compensationGradientMonotonicity(annualFullModel: NumberedModelRow[]): number | null {
    if (annualFullModel.length === 0) {
      return null;
    }
  
    // Schritt 1: Daten nach 'iso_dow' gruppieren
    const groupedData = new Map<string, NumberedModelRow[]>();
  
    for (const entry of annualFullModel) {
      const key = String(entry.iso_dow);
      if (!groupedData.has(key)) {
        groupedData.set(key, []);
      }
      groupedData.get(key)!.push(entry);
    }
  
    // Schritt 2: Für jede Gruppe die Monotonizität berechnen
    const monotonicityValues: number[] = [];
  
    for (const [iso_dow, group] of Array.from(groupedData.entries())) {
      // Sortiere die Gruppe nach 'oat'
      const sortedGroup = group.slice().sort((a, b) => a.oat - b.oat);
  
      // Extrahiere die 'pred'-Werte in der sortierten Reihenfolge
      const preds = sortedGroup.map(entry => entry.expected);
  
      // Berechne die Differenzen der 'pred'-Werte
      const diffs: number[] = [];
      for (let i = 1; i < preds.length; i++) {
        diffs.push(preds[i] - preds[i - 1]);
      }
  
      // Berechne die Vorzeichen der Differenzen
      const signs = diffs.map(diff => Math.sign(diff));
  
      // Berechne den Mittelwert der Vorzeichen
      const meanSign = signs.length > 0 ? signs.reduce((sum, val) => sum + val, 0) / signs.length : 0;
  
      monotonicityValues.push(meanSign);
    }
  
    // Schritt 3: Berechne den Median der Monotonizitätswerte
    monotonicityValues.sort((a, b) => a - b);
  
    const n = monotonicityValues.length;
    let medianMonotonicity: number | null;
  
    if (n === 0) {
      medianMonotonicity = null;
    } else if (n % 2 === 1) {
      medianMonotonicity = monotonicityValues[Math.floor(n / 2)];
    } else {
      medianMonotonicity = (monotonicityValues[n / 2 - 1] + monotonicityValues[n / 2]) / 2;
    }
  
    return medianMonotonicity;
  }
  
  // Gleichheit der Erwartungswerte nach Wochentag
//
// Berechnet den Gini-Koeffizienten der durchschnittlichen vorhergesagten Werte, gruppiert nach ISO-Wochentag.
//
// @param annualFullModel Die modellierten Messwerte
// @return Gini-Koeffizient nach Wochentag

 export function isoDowGini(annualFullModel: NumberedModelRow[]): number | null {
    if (annualFullModel.length === 0) {
      return null;
    }
  
    // Daten nach 'iso_dow' gruppieren und den Durchschnitt der 'pred'-Werte berechnen
    const groupedData = new Map<string, number[]>();
  
    for (const entry of annualFullModel) {
      const key = String(entry.iso_dow);
      const predValue = entry.expected;
      if (!isNaN(predValue)) {
        if (!groupedData.has(key)) {
          groupedData.set(key, []);
        }
        groupedData.get(key)!.push(predValue);
      }
    }
  
    const isoDowExpectedValues: number[] = [];
  
    groupedData.forEach((preds, key) => {
      const meanPred = preds.reduce((sum, val) => sum + val, 0) / preds.length;
      isoDowExpectedValues.push(meanPred);
    });
  
    // Gini-Koeffizienten der durchschnittlichen vorhergesagten Werte berechnen
    const giniCoefficient = computeGini(isoDowExpectedValues);
  
    return giniCoefficient;
  }
  
  function computeGini(values: number[]): number {
    const n = values.length;
    if (n === 0) {
      return NaN;
    }
  
    const sortedValues = values.slice().sort((a, b) => a - b);
    const cumulativeValues = sortedValues.reduce((acc, value, index) => {
      acc.push((acc[index - 1] || 0) + value);
      return acc;
    }, [] as number[]);
  
    const sumOfValues = cumulativeValues[cumulativeValues.length - 1];
    let giniSum = 0;
  
    for (let i = 0; i < n; i++) {
      giniSum += (i + 1) * sortedValues[i];
    }
  
    const gini = (2 * giniSum) / (n * sumOfValues) - (n + 1) / n;
  
    return gini;
  }
  
  export function getDayFeatures(annualFullModel: NumberedModelRow[]): DayFeatures {
    const { rising, falling } = annualAsymmetryWaste(annualFullModel);
    const { annual_load, seasonalbaseload, balance } = annualSeasonalBaseloadAndBalance(annualFullModel);
  
    return {
      dayDistribution: isoDowGini(annualFullModel) ?? 0,
      compensation: compensationGradientMonotonicity(annualFullModel) ?? 0,
      annualLoad: annual_load ?? 0,
      seasonalBaseLoad: seasonalbaseload ?? 0,
      seasonalBalance: balance ?? 0,
      convexityExcess: 0, // Implement this if needed
      asymmetryRising: rising ?? 0,
      asymmetryFalling: falling ?? 0,
      asymmetryWaste: Math.abs((rising ?? 0) - (falling ?? 0)),
    };
  }
  
  export interface DayFeatures{
    dayDistribution:number
    compensation:number
    annualLoad:number
    seasonalBaseLoad:number
    seasonalBalance:number
    convexityExcess:number
    asymmetryRising:number
    asymmetryFalling:number
    asymmetryWaste:number
  }
