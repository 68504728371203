import React, { useMemo  } from 'react';
import { Group } from '@visx/group';
import { scaleTime, scaleLinear } from '@visx/scale';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { LinePath, AreaClosed } from '@visx/shape';
 
import { bisector, extent, max, min } from 'd3-array';
import { timeFormat } from 'd3-time-format';
import { EventHistoryChartProps, EventData } from '../../types';
import _ from 'lodash';
 
import { GridRows, GridColumns } from '@visx/grid';
import { localPoint } from '@visx/event';
import { useTooltip } from '@visx/tooltip';

const margin = { top: 40, right: 30, bottom: 50, left: 60 };

const daycolors: string[] = [
  '#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2',
];

 
const EventHistoryChart: React.FC<EventHistoryChartProps> = ({ data, width, height }) => {

  const processedData = useMemo(() => {
    const grouped = _.groupBy(data, 'event_no');
    return Object.fromEntries(
      Object.entries(grouped).map(([key, events]) => {
        const sorted = _.sortBy(events, (d) => new Date(d.ts).getTime());
        let cumulativeWaste = 0;
        const processedEvents = sorted.map((event) => ({
          ...event,
          cum_waste: (cumulativeWaste += 24 * event.waste),
        }));
        return [parseInt(key, 10), processedEvents];
      })
    );
  }, [data]);

  const allDataPoints = useMemo(() => Object.values(processedData).flat(), [processedData]);

  const xExtent = extent(allDataPoints, (d) => new Date(d.ts)) as [Date, Date];
  const yMax = max(allDataPoints, (d) => d.cum_waste) || 0;
  const yMin = min(allDataPoints, (d) => d.cum_waste) || 0;

  const xScale = useMemo(
    () => scaleTime({
      domain: xExtent,
      range: [margin.left, width - margin.right],
    }),
    [width, xExtent]
  );

  const yScale = useMemo(
    () => scaleLinear({
      domain: [Math.min(yMin, 0), Math.max(yMax, 0)],
      range: [height - margin.bottom, margin.top],
      nice: true,
    }),
    [height, yMin, yMax]
  );

  const bisectDate = useMemo(() => bisector<EventData, Date>((d) => new Date(d.ts)).left, []);

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<EventData>();

  

  return (
    <div style={{ position: 'relative' }}>
      <svg width={width} height={height}>
        <Group>
          <rect width={width} height={height} fill="#ffffff" />
          <GridRows
            scale={yScale}
            width={width - margin.left - margin.right}
            height={height - margin.top - margin.bottom}
            left={margin.left}
            stroke="#e0e0e0"
            strokeOpacity={0.5}
            strokeDasharray="2,2"
          />
          <GridColumns
            scale={xScale}
            width={width - margin.left - margin.right}
            height={height - margin.top - margin.bottom}
            top={margin.top}
            stroke="#e0e0e0"
            strokeOpacity={0.5}
            strokeDasharray="2,2"
          />
          <AxisBottom
            top={height - margin.bottom}
            scale={xScale}
            numTicks={width > 520 ? 10 : 5}
          />
          <AxisLeft
            left={margin.left}
            scale={yScale}
            numTicks={5}
            label="Event Impact Cumulative (kWh)"
            labelProps={{
              fill: '#000',
              fontSize: 12,
              fontWeight: 'bold',
              textAnchor: 'middle',
              dy: '-2em',
            }}
          />
          {Object.entries(processedData).map(([event_no, events]) => {
            const color = daycolors[(1 + (parseInt(event_no, 10) % 7)) % daycolors.length];
            return (
              <Group key={`group-${event_no}`}>
                <AreaClosed
                  data={events}
                  x={(d) => xScale(new Date(d.ts)) || 0}
                  y={(d) => yScale(d.cum_waste||0) || 0}
                  y0={yScale(0)}
                  yScale={yScale}
                  fill={color}
                  fillOpacity={0.3}
                  stroke={color}
                  onMouseMove={(event) => {
                    const { x } = localPoint(event) || { x: 0 };
                    const x0 = xScale.invert(x);
                    const index = bisectDate(events, x0, 1);
                    const d0 = events[index - 1];
                    const d1 = events[index];
                    const d = x0?.getTime() - new Date(d0?.ts).getTime() > new Date(d1?.ts).getTime() - x0.getTime() ? d1 : d0;
                    showTooltip({
                      tooltipData: {
                       
                        ...d,
                      },
                      tooltipLeft: xScale(new Date(d.ts)),
                      tooltipTop: yScale(d.cum_waste),
                    });
                  }}
                  onMouseLeave={() => hideTooltip()}
                />
                <LinePath
                  data={events}
                  x={(d) => xScale(new Date(d.ts)) || 0}
                  y={(d) => yScale(d.cum_waste|| 0) || 0}
                  stroke={color}
                  strokeWidth={2}
                  curve={undefined}
                />
              </Group>
            );
          })}
         
        </Group>
      </svg>
      {tooltipOpen && tooltipData && (
        <div
          style={{
            position: 'absolute',
            left: tooltipLeft,
            top: tooltipTop,
            padding: '8px',
            backgroundColor: 'rgba(0,0,0,0.8)',
            color: 'white',
            fontSize: '12px',
            borderRadius: '4px',
            pointerEvents: 'none',
          }}
        >
          <div>Event: {tooltipData.event_no}</div>
          <div>Impact: {tooltipData.cum_waste?.toFixed(2)} kWh</div>
          <div>Time: {timeFormat('%Y-%m-%d %H:%M')(new Date(tooltipData.ts))}</div>
        </div>
      )}
    </div>
  );
};

export default EventHistoryChart;
