import React, { useRef, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';

import { NumberedModelRow } from '../domain/NumberedModelRow';
import { numberEvents } from '../model/events';
import TimeChart from './charts/TimeChart';
import OATChart from './charts/OATChart';
import EventHistoryChart from './charts/EventHistoryChart';
import ProfileChart from './charts/ProfileChart';
import WasteExpectedProfileChart from './charts/WasteExpectedChart';
import { annual_model, DayFeatures, getDayFeatures } from '../model/kfeature';

import { KPIBox, KPIData } from './KPIBox'; // New component
import './AppContent.css'; // Add this import for the new CSS file
import { KServerResponse } from '../domain/KServerResponse';
import NtileEventProfileChart from './charts/NtileEventProfileChart';

const SingleMeterKEMS: React.FC = () => {

  const { meterkey } = useParams<{ meterkey: string }>();
  
  const [meterData, setMeterData] = useState<KServerResponse | null>(null);
 
  const [error, setError] = useState<string | null>(null);

  const [chartDimensions, setChartDimensions] = useState({ width: 1000, height: 400 });

  useEffect(() => {
    const updateDimensions = () => {
      const width = window.innerWidth * 0.9; // 90% of window width
      const height = window.innerHeight * 0.4; // 40% of window height
      setChartDimensions({ width, height });
    };

    updateDimensions(); // Set initial dimensions
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!meterkey) {
        console.error('Meterkey is undefined.');
        setError('Meterkey is undefined');
        return;
      }

      const fetchData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/meter_data`, {
            params: { meter_key: meterkey }
          });
          setMeterData(response.data);
         
        } catch (error) {
          console.error('Error fetching event model:', error);
          setError('Failed to fetch event model. Please try again later.');
        }
      };

     

      fetchData();
      
    };

    fetchData();
  }, [meterkey, "location.pathname"]);

  
  return (
    <React.Fragment>
      {error ? (
        <div className="error-container">
          <Message severity="error" text={error} />
        </div>
      ) : meterData ? (
        <React.Fragment>
          <div className="diagramm-container">
            <div className="diagramm card">
                <h4>Model & actual Power</h4>
              <TimeChart data={meterData.numbered_event_model} width={chartDimensions.width} height={chartDimensions.height} />
            </div>
          
            <div className="diagramm card">
                <h4>Event History</h4>
              <EventHistoryChart data={meterData.numbered_event_model||[]} width={chartDimensions.width} height={chartDimensions.height} />
            </div>
            <div className="diagramm card">
            <h4></h4>
              <OATChart data={meterData.numbered_event_model} width={chartDimensions.width} height={chartDimensions.height} />
            </div>
            <div className="diagramm card">
            <h4>Event & Prior Year By Time of Week and Temperature</h4>
              {meterData.pentile_profile && meterData.pentiled_event_profile ? (
                <NtileEventProfileChart pentiledModel={meterData.pentile_profile} pentiledEventModel={meterData.pentiled_event_profile} width={chartDimensions.width} height={chartDimensions.height} />
              ) : (
                <div className="flex justify-content-center">
                  <ProgressSpinner />
                </div>
              )}
            </div>
            
          </div>
        </React.Fragment>
      ) : (
        <div className="flex justify-content-center">
          <ProgressSpinner />
        </div>
      )}
    </React.Fragment>
  );
};

export default SingleMeterKEMS;
