import React, { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import { User } from '../domain/User';
import { Menu } from 'primereact/menu';
import { useRef } from 'react';

interface UserInfoProps {
    onUserChange: (userInfo: any) => void;
    user: User;
}

const UserInfo: React.FC<UserInfoProps> = ({ onUserChange, user }): JSX.Element => {
    const menuRef = useRef<Menu>(null);
    const [imageError, setImageError] = useState(false);

    const login = useGoogleLogin({
        onSuccess: async tokenResponse => {
            try {
                // Fetch user info using the access token
                const userInfo = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
                });
                setImageError(false);
                onUserChange(userInfo.data);
            } catch (error) {
                console.error("Failed to fetch user info:", error);
            }
        },
        onError: error => {
            console.error("Login Failed:", error);
        }
    });

    const getInitials = (name: string) => {
        if (!name) return '';
        return name
            .split(' ')
            .map(part => part[0])
            .join('')
            .toUpperCase();
    };

    const menuItems = [
        {
            label: 'Logout',
            icon: 'pi pi-sign-out',
            command: () => {
                // Add logout logic here
                onUserChange(null);
                setImageError(false);
            }
        }
    ];

    if (user && user.email) {
        return (
            <div className="user-info">
                <div onClick={(e) => menuRef.current?.toggle(e)} style={{ cursor: 'pointer' }}>
                    {user.picture && !imageError ? (
                        <img
                            src={user.picture}
                            alt={user.name}
                            className="user-avatar"
                            onError={() => setImageError(true)}
                            key={user.picture}
                        />
                    ) : (
                        <div className="user-initials">
                            {getInitials(user.name)}
                        </div>
                    )}
                </div>
                <Menu model={menuItems} popup ref={menuRef} />
            </div>
        );
    }

    return (
        <GoogleLogin
            onSuccess={credentialResponse => {
                // @ts-ignore - Temporary fix for type mismatch
                login();
            }}
            onError={() => console.log('Login Failed')}
        />
    );
};

export default UserInfo;