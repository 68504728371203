import { Button } from "primereact/button";
import {useAuth} from "react-oidc-context";

export function Welcome() {
  const auth = useAuth();
  const handleLogin = () => {
    console.log('###################Logging in');
    const currentUrl = window.location.pathname + window.location.search;
    console.log('###################Setting postLoginRedirect to', currentUrl);
    sessionStorage.setItem('postLoginRedirect', currentUrl);
    auth.signinRedirect();
  };
  let login=auth.isAuthenticated?<span/>:<Button  onClick={(event) =>handleLogin()}>login</Button>;
  return (<div>

    {login}
  </div>);
}
