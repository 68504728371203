import React from 'react';
import { scaleTime, scaleLinear } from '@visx/scale';
import { extent, max } from 'd3-array';
import { AreaClosed } from '@visx/shape';
import {
  AxisBottom,
  AxisLeft,
} from '@visx/axis';
import { GridRows, GridColumns } from '@visx/grid';
import { Group } from '@visx/group';
import { curveMonotoneX } from '@visx/curve';
import { timeFormat } from 'd3-time-format';
import { PentileDataPoint } from '../../domain/PentileDataPoint';


interface NtileEventProfileChartProps {
  pentiledModel: PentileDataPoint[];
  pentiledEventModel: PentileDataPoint[];
  title?: string;
  width: number;
  height: number;
}

const NtileEventProfileChart: React.FC<NtileEventProfileChartProps> = ({
  pentiledModel,
  pentiledEventModel,
  title,
  width,
  height,
}) => {
  const [visiblePentiles, setVisiblePentiles] = React.useState<{[key: number]: boolean}>({
    1: true,
    2: true, 
    3: true,
    4: true,
    5: true
  });

  const togglePentile = (pentile: number) => {
    setVisiblePentiles(prev => ({
      ...prev,
      [pentile]: !prev[pentile]
    }));
  };

  const margin = { top: 50, right: 50, bottom: 100, left: 80 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

   
  const processedPentiledModel = pentiledModel.map((d) => ({
    ...d,
    date: new Date(d.time_of_week),
  }));

  const processedPentiledEventModel = pentiledEventModel.map((d) => ({
    ...d,
    date: new Date(d.time_of_week ),
  }));

  const allDates = [
    ...processedPentiledModel.map((d) => d.date),
    ...processedPentiledEventModel.map((d) => d.date),
  ];

  const xDomain = extent(allDates) as [Date, Date];

  const allReadings = [
    ...processedPentiledModel.map((d) => d.reading),
    ...processedPentiledEventModel.map((d) => d.reading),
  ];

  const yMaxValue = max(allReadings) || 0;

  const xScale = scaleTime({
    domain: xDomain,
    range: [0, innerWidth],
  });

  const yScale = scaleLinear({
    domain: [0, yMaxValue],
    range: [innerHeight, 0],
    nice: true,
  });

  const pentileColors = ['#0000ff', 'lightblue', '#2ca02c', 'orange', '#ff0000'];
  const pentileNames = ['Very Cool', 'Cool', 'Mild', 'Warm', 'Very Hot'];

  const priorYearAreas = [];
  const eventMarkers = [];

  for (let i = 1; i <= 5; i++) {
    const pentile = i;
    const pcol = pentileColors[i - 1];
    const pname = pentileNames[i - 1];
    const isVisible = visiblePentiles[pentile];

    if (isVisible) {
      const pentileData = processedPentiledModel
        .filter((d) => d.pentile === pentile)
        .sort((a, b) => a.date.getTime() - b.date.getTime());
      const eventData = processedPentiledEventModel
        .filter((d) => d.pentile === pentile)
        .sort((a, b) => a.date.getTime() - b.date.getTime());

      priorYearAreas.push(
        <AreaClosed<PentileDataPoint>
          key={`prior-year-area-${pentile}`}
          data={pentileData}
          x={(d) => xScale(d.date) ?? 0}
          y={(d) => yScale(d.reading) ?? 0}
          yScale={yScale}
          fill={pcol}
          fillOpacity={0.7}
          strokeWidth={0}
          curve={curveMonotoneX}
        />
      );

      eventMarkers.push(
        eventData.map((d, idx) => (
          <circle
            key={`event-marker-${pentile}-${idx}`}
            cx={xScale(d.date)}
            cy={yScale(d.reading)}
            r={3}
            fillOpacity={0.7}
            stroke={pcol}
          />
        ))
      );
    }
  }

  const formatDate = timeFormat('%A\n%H:%M');

  const legendEntries = [];

  for (let i = 1; i <= 5; i++) {
    const pname = pentileNames[i - 1];
    const pcol = pentileColors[i - 1];
    const isVisible = visiblePentiles[i];

    legendEntries.push(
      <div
        key={`legend-entry-${i}`}
        style={{ 
          display: 'flex', 
          alignItems: 'center', 
          marginRight: '15px',
          cursor: 'pointer',
          opacity: isVisible ? 1 : 0.5 
        }}
        onClick={() => togglePentile(i)}
      >
        <svg width={10} height={10}>
          <rect width={10} height={10} fill={pcol} fillOpacity={0.7} />
        </svg>
        <span style={{ marginLeft: '5px', fontSize: '12px' }}>{`Year Prior ${pname}`}</span>
      </div>
    );

    legendEntries.push(
      <div
        key={`legend-entry-event-${i}`}
        style={{ 
          display: 'flex', 
          alignItems: 'center', 
          marginRight: '15px',
          cursor: 'pointer',
          opacity: isVisible ? 1 : 0.5
        }}
        onClick={() => togglePentile(i)}
      >
        <svg width={10} height={10}>
          <circle cx={5} cy={5} r={5} fill={pcol} />
        </svg>
        <span style={{ marginLeft: '5px', fontSize: '12px' }}>{`Event ${pname}`}</span>
      </div>
    );
  }

  return (
    <div>
      {title && <h3 style={{ textAlign: 'center' }}>{title}</h3>}
      <svg width={width} height={height}>
        <Group left={margin.left} top={margin.top}>
          <GridRows scale={yScale} width={innerWidth} stroke="#ddd" strokeWidth={1} />
          <GridColumns scale={xScale} height={innerHeight} stroke="#ddd" strokeWidth={1} />
          {priorYearAreas}
          {eventMarkers}
          <AxisBottom
            top={innerHeight}
            scale={xScale}
            numTicks={15}
            tickFormat={(date) => formatDate(date as Date)}
            tickLabelProps={() => ({
              fontSize: 8,
              textAnchor: 'end',
             
              
            })}
            stroke="#000"
            tickStroke="#000"
          />
          <AxisLeft
            scale={yScale}
            tickFormat={(d) => d.toString()}
            tickLabelProps={() => ({
              fontSize: 10,
              textAnchor: 'end',
              dy: '0.33em',
            })}
            label="Power (kW)"
           
            stroke="#000"
            tickStroke="#000"
          />
        </Group>
      </svg>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          marginTop: '10px',
        }}
      >
        {legendEntries}
      </div>
    </div>
  );
};

export default NtileEventProfileChart;
