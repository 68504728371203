import React, {useState} from 'react';
import {Checkbox} from 'primereact/checkbox';

interface OccupancyHoursProps {
  onChange?: (occupancy: { [key: string]: boolean[] }) => void,
  initialOccupancy: { [key: string]: boolean[] };
}

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];



const OccupancyHours: React.FC<OccupancyHoursProps> = ({onChange, initialOccupancy}) => {
  const [occupancy, setOccupancy] = useState<{ [key: string]: boolean[] }>(initialOccupancy);

  const toggleOccupancy = (day: string, index: number) => {
    const updatedOccupancy = {
      ...occupancy,
      [day]: occupancy[day].map((selected, idx) => idx === index ? !selected : selected)
    };
    setOccupancy(updatedOccupancy);
    if (onChange) {
      onChange(updatedOccupancy);
    }
  };

  const renderDayColumn = (day: string) => {
    return (
      <div key={day} className={'occupancyStep'}>
        <h5>{day}</h5>
        {occupancy[day].map((selected, index) => {
          const hour = Math.floor(index / 60);
          const minutes = index-hour*60;
          return (
            <div key={`${day}-${index}`}>
              <Checkbox inputId={`${day}-${index}`} onChange={() => toggleOccupancy(day, index)} checked={selected}/>
              <label htmlFor={`${day}-${index}`} style={{margin: '0 10px'}}>
                {`${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`}
              </label>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      {daysOfWeek.map(day => renderDayColumn(day))}
    </div>
  );
};

export default OccupancyHours;
