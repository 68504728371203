import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "react-oidc-context";

const CallbackHandler: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated) {
      const postLoginRedirect = sessionStorage.getItem('postLoginRedirect');
      //sessionStorage.removeItem('postLoginRedirect');
      console.log('Navigating to', postLoginRedirect);
      navigate(postLoginRedirect || '/');
    }
  }, [auth, navigate]);

  return <div>Loading...</div>;
}

export default CallbackHandler;
