// WasteExpectedProfileChart.tsx
import React from 'react';
import { Group } from '@visx/group';
import { scaleLinear, scaleOrdinal } from '@visx/scale';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { Circle } from '@visx/shape';
import { LegendOrdinal } from '@visx/legend';
import { Text } from '@visx/text';
import { useTooltip, TooltipWithBounds, defaultStyles } from '@visx/tooltip';
import { GridRows, GridColumns } from '@visx/grid';

// Definiere die Cluster-Namen und Farben
const clusterNames = ["Low", "Inter.", "High"];
const colors = ["#00ff00", "#aa00aa", "#ff0000", "purple"];

// Breite und Höhe des Diagramms
const margin = { top: 60, right: 150, bottom: 60, left: 80 };

// Definiere die Props der Komponente
interface WasteExpectedProfileChartProps {
  typical_profile: ProfileData[];
  title?: string;
  width?: number;
  height?: number;
}

// Die Hauptkomponente
const WasteExpectedProfileChart: React.FC<WasteExpectedProfileChartProps> = ({
  typical_profile,
  title = "Waste vs Expected by Minute of Week",
  width = 1000,
  height = 300
}) => {
  // Tooltip Hook
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<ProfileData>();

  // Berechne den verfügbaren Bereich
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  // X- und Y-Werte extrahieren
  const xValues = typical_profile.map(d => d.predicted);
  const yValues = typical_profile.map(d => d.waste);

  // Skalen definieren
  const xScale = scaleLinear<number>({
    domain: [
      Math.min(...xValues) * 0.95,
      Math.max(...xValues) * 1.05
    ],
    range: [0, innerWidth],
  });

  const yScale = scaleLinear<number>({
    domain: [Math.min(...yValues) * 1.05, Math.max(...yValues) * 1.05],
    range: [innerHeight, 0],
  });

  // Farbskala basierend auf km3
  const colorScale = scaleOrdinal<number, string>({
    domain: [1, 2, 3],
    range: colors.slice(0, 3), // "green","orange","red"
  });

  return (
    <div style={{ position: 'relative' }}>
      <svg width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          {/* Gridlines */}
          <GridRows
            scale={yScale}
            width={innerWidth}
            height={innerHeight}
            stroke="#e0e0e0"
          />
          <GridColumns
            scale={xScale}
            width={innerWidth}
            height={innerHeight}
            stroke="#e0e0e0"
          />

          {/* Achsen */}
          <AxisLeft
            scale={yScale}
            label="Waste"
            labelProps={{
              fill: 'black',
              fontSize: 12,
              textAnchor: 'middle',
            }}
            tickFormat={(d) => d.toString()}
          />
          <AxisBottom
            top={innerHeight}
            scale={xScale}
            label="Expected kW"
            labelProps={{
              fill: 'black',
              fontSize: 12,
              textAnchor: 'middle',
            }}
            tickFormat={(d) => d.toString()}
          />

          {/* Datenpunkte */}
          {typical_profile.map((d, i) => (
            <Circle
              key={i}
              cx={xScale(d.predicted)}
              cy={yScale(d.waste)}
              r={6}
              fill={colorScale(d.km3)}
              fillOpacity={1}
              stroke="black"
              strokeWidth={1}
              onMouseMove={(event: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
                showTooltip({
                  tooltipData: d,
                  tooltipLeft: xScale(d.predicted) + margin.left,
                  tooltipTop: yScale(d.waste) + margin.top,
                });
              }}
              onMouseLeave={() => hideTooltip()}
            />
          ))}

          {/* Legende */}
          <LegendOrdinal
            scale={colorScale}
            labelFormat={(label) => clusterNames[label - 1]}
            direction="column"
            labelMargin="0 0 4px 0"
            shapeMargin="0 10px 0 0"
          >
            {(labels) => (
              <Group top={0} left={innerWidth + 20}>
                {labels.map((label, i) => (
                  <Group key={`legend-${i}`} top={i * 20}>
                    <Circle fill={label.value} r={5} />
                    <Text dx={10} verticalAnchor="middle">
                      {label.text}
                    </Text>
                  </Group>
                ))}
              </Group>
            )}
          </LegendOrdinal>
        </Group>
      </svg>

      {/* Tooltip */}
      {tooltipOpen && tooltipData && (
        <TooltipWithBounds
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            ...defaultStyles,
            backgroundColor: 'rgba(255, 255, 255, 0.8)', 
            minWidth: '180px',
          }}
        >
          <div style={{ display: 'grid', 
             backgroundColor: 'rgba(255, 255, 255, 0.8)', 
            gridTemplateColumns: '1fr 1fr', gap: '4px' }}>
            <div style={{ textAlign: 'left' }}><strong>Cluster:</strong></div>
            <div style={{ textAlign: 'right' }}>{clusterNames[tooltipData.km3 - 1]}</div>
            <div style={{ textAlign: 'left' }}><strong>Expected kW:</strong></div>
            <div style={{ textAlign: 'right' }}>{tooltipData.predicted.toFixed(2)}</div>
            <div style={{ textAlign: 'left' }}><strong>Waste:</strong></div>
            <div style={{ textAlign: 'right' }}>{tooltipData.waste.toFixed(2)}</div>
          </div>
        </TooltipWithBounds>
      )}
    </div>
  );
};

export default WasteExpectedProfileChart;
