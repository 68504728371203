export interface WeatherStation{
  USAF: string;
  WBAN: string;
  STATION_NAME: string;
  CTRY: string;
  ST: string;
  LAT: number;
  LON: number;
  BEGIN: string;
  END: string;
}

export interface DateMetrics {
  startDate: string;
  endDate: string;
  totalDays: number;
  readingsPerDay: number;
}
export interface CombinedReading {
  DateTime: Date;
  consumption: number;
  oat: number;
}

export interface DataPoint {
  timestamp: Date;
  oat: number;
  consumption: number;
  cluster?: number;
  predicted_energy_consumption?: number;
}

export function interpolateArray(inputArray: number[]): number[] {
  if (inputArray.length !== 24) {
    throw new Error("Input array must have exactly 24 elements.");
  }

  const outputArray: number[] = [];
  const factor = 2; // We need to double the number of elements

  for (let i = 0; i < inputArray.length - 1; i++) {
    const start = inputArray[i];
    const end = inputArray[i + 1];

    outputArray.push(start);

    // Interpolated value
    const interpolatedValue = start + (end - start) / factor;
    outputArray.push(interpolatedValue);
  }

  // Add the last element
  outputArray.push(inputArray[inputArray.length - 1]);

  return outputArray;
}



